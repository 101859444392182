import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
  PhoneOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from "@ant-design/icons/lib/icons";
import React from "react";
import styled from "styled-components";

export default function DetailsComponent() {
  return (
    <React.Fragment>
      <DetailsLayoutWrapper>
        <DetailsLayoutTitle>Address</DetailsLayoutTitle>

        <DetailsLayoutDescription>
          <span>Registered Address : </span>
          <br />
          94, Broad Street, Lagos Island
        </DetailsLayoutDescription>

        <DetailsLayoutDescription>
          <span>Head Office</span>
          <br />
          240b Kofo Abayomi Street, Victoria Island, Lagos
        </DetailsLayoutDescription>

        <DetailsLayoutDescription>
          <span>UK Office</span>
          <br />
          Unit 3A, 34-35 Hatton Garden, Holborn, London EC1N 8DX UK
        </DetailsLayoutDescription>

        <DetailsLayoutTitle>Phone</DetailsLayoutTitle>
        <DetailsLayoutDescription>
          <DetailsLayoutIcons>
            <PhoneOutlined />
          </DetailsLayoutIcons>
          +2348099997555
          <br />
          +2349077275101
          <br />
          +442034321088
        </DetailsLayoutDescription>

        <DetailsLayoutTitle>Support</DetailsLayoutTitle>
        <DetailsLayoutDescription>
          <DetailsLayoutIcons>
            <MailOutlined />
          </DetailsLayoutIcons>
          <span>info@assetandequitygroup.com</span>
          <br />
        </DetailsLayoutDescription>

        <DetailsLayoutTitle>Follow Us</DetailsLayoutTitle>
        <DetailsLayoutIcons>
          <a
            href="https://www.facebook.com/Assetandequityg"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookOutlined />
          </a>
          <a
            href="https://www.instagram.com/assetandequityg/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramOutlined />
          </a>
          <a
            href="https://ng.linkedin.com/company/asset-equity-group?trk=similar-pages"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinOutlined />
          </a>
          <a
            href="https://www.youtube.com/channel/UC0F-ouLrzWOVUdvpHj_rTEQ"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeOutlined />
          </a>
          <a
            href="https://twitter.com/assetandequityg"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterOutlined />
          </a>
        </DetailsLayoutIcons>
      </DetailsLayoutWrapper>
    </React.Fragment>
  );
}

const DetailsLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const DetailsLayoutTitle = styled.h4`
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  color: #000;
`;
const DetailsLayoutDescription = styled.p`
  width: 100%;
  font-size: 17px;
  line-height: 25px;
  color: #000;
  padding: 10px 0px;
  display: block;

  & span {
    font-weight: 500;
    text-transform: capitalize;
  }
`;

const DetailsLayoutIcons = styled.div`
  font-size: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  & span {
    padding-right: 10px;
  }
  a {
    color: #000;
  }
`;
